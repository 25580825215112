<template>
    <section class="content">
        <div class="card card-primary row">
          <div class="col-12" v-if="width_screen <= 820 ">
            <form role="form" @submit.prevent="saveItem">
              <div class="card-body">
                <div class="input-group mb-3" v-for="(item, idx) in dataCustomCourier" :key="item.courier_id">
                  <div
                    :id = "item.courier_id+'box'"
                    :style="item.status == '1'? {flex: '1 1 auto', width: '1%',  border:'2px solid #FF7203', backgroundColor: '#FFE1C9'} : {flex: '1 1 auto', width: '1%',  border:'2px solid #D3D3D3', backgroundColor: 'white'}"
                    @click="changeCourier(item.courier_id)"
                    class="w-auto">
                    <div class="row" style="padding-left: 25px; padding-right: 25px; align-items: center;">
                      <div class="col-4">
                        <img :src="getKurirLogo(item.prefix)"
                        style="height:60px;" />
                      </div>
                      <div class="col-5" style="display: flex; align-items: center; padding-top: 10px; padding-left: 25px;">
                          <h6 class="description-header">{{ item.nama }}</h6>
                      </div>
                      <div class="col-3" style="text-align: end; padding-right: 25px; padding-top: 5px;">
                        <span>
                          <input
                            style="accent-color: #FF7203;"
                            type="checkbox"
                            v-on:click="changeCourier($event.target.value, null)"
                            :value="item.courier_id"
                            :checked="item.status == '0' ? false : true"
                            v-on:input="item.courier_id = $event.target.value"
                            :id="item.courier_id+'checked'"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div style="justify-content: center; text-align: center; align-items: center; padding-bottom: 25px;">
                  <button type="button" class="col-11 btn" style="background-color: #FF7203; color: white; padding: 10px;" data-dismiss="modal" v-on:click="saveCourier()"><strong>Simpan</strong></button>
                </div>
            </form>
          </div>
          <div class="col-6" v-else>
            <form role="form" @submit.prevent="saveItem">
              <div class="card-body">
                <div class="input-group mb-3" v-for="(item, idx) in dataCustomCourier" :key="item.courier_id">
                  <div
                  :id = "item.courier_id+'box'"
                    :style="item.status == '1'? {flex: '1 1 auto', width: '1%',  border:'2px solid #FF7203', backgroundColor: '#FFE1C9'} : {flex: '1 1 auto', width: '1%',  border:'2px solid #D3D3D3', backgroundColor: 'white'}"
                    @click="changeCourier(item.courier_id)"
                    class="w-auto">
                    <div class="row" style="padding-left: 25px; padding-right: 25px; align-items: center;">
                      <div class="col-4">
                        <img :src="getKurirLogo(item.prefix)"
                          style="height:60px;" />
                      </div>
                      <div class="col-4" style="display: flex; align-items: center; padding-top: 10px; padding-left: 55px;">
                          <h6 class="description-header">{{ item.nama }}</h6>
                      </div>
                      <div class="col-4" style="text-align: end; padding-right: 25px; padding-top: 5px;">
                        <span>
                          <input
                            style="accent-color: #FF7203;"
                            type="checkbox"
                            v-on:click="changeCourier($event.target.value)"
                            :value="item.courier_id"
                            :checked="item.status == '0' ? false : true"
                            v-on:input="item.courier_id = $event.target.value"
                            :id="+item.courier_id+'checked'"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="justify-content: center; text-align: center; align-items: center; padding-bottom: 25px;">
                <button type="button" class="col-11 btn" style="background-color: #FF7203; color: white; padding: 10px;" data-dismiss="modal" v-on:click="saveCourier()"><strong>Simpan</strong></button>
              </div>
            </form>
          </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" ref="formDialogKurir" data-backdrop="static">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <form role="form" @submit.prevent="saveItem">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div style="justify-content: center; align-items: center; text-align: center;">
                      <img :src="require(`../assets/img/ok.png`)" style="width: 100%"/>
                      <h4 class="modal-title" style="padding-top: 10px;">Berhasil merubah kustom kurir</h4>
                  </div>
                </div>
                <div style="justify-content: center; text-align: center; align-items: center; padding-bottom: 20px;">
                  <button type="button" class="col-11 btn" style="background-color: #FF7203; color: white; padding: 10px;" data-dismiss="modal"><strong>Kembali</strong></button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import Vue from "vue";
import { createTable, authFetch, formatCurrency, charsRemover } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import ToggleButton from "vue-js-toggle-button";
import Warehouse from "@/dialog/Warehouse";
import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "dropzone/dist/dropzone.css";
import "vue-select/dist/vue-select.css";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import VTooltip from 'v-tooltip';
import "icheck-material";
import "daterangepicker/daterangepicker.css";
import 'v-tooltip/dist/v-tooltip.css';

Vue.use(ToggleButton);
Vue.use(VTooltip, {
    themes: {
        'boscod': {
        $extend: 'tooltip',
        triggers: [
            'hover',
            'focus',
            'click',
            'touch',
        ],
        placement: 'auto',
        },
    },
    });

export default {
  data() {
    return {
      listCourier: [],
      method: "POST",
      dataCustomCourier: [],
      dataPayload: [],
      width_screen: window.innerWidth,
    };
  },
  created: function() {
    this.loadCourier();
    this.getDataCourier();
  },
  methods: {
    getKurirLogo(kurir) {
      const fileName = kurir.toLowerCase();
      return require(`../assets/img/${fileName}.png`); // the module request
      
    },
    getDataCourier() {
      let route = "/order/courier/courier_setting";
      try {
        authFetch(route).then(res => {
          res.json().then(json => {
            console.log("popo", json)
            this.dataCustomCourier = json
          });
        });
        
      } catch (error) {
        
      }
    },
    loadCourier() {
      let route = "/order/kurir";
      try {
        authFetch(route).then(res => {
          res.json().then(json => {
            if(json.success==true){
              this.listCourier = json.data;
              $('.loading-overlay').removeClass('show');
            }else{
              Swal.fire({
                title: "Oops...",
                icon: "error",
                text: json.message,
                showCloseButton: true
              });
            }
          });
        });
        
      } catch (error) {
        
      }
    },
    changeCourier: function(value)
    {
      var nilai_check = $('#'+value+'checked').prop("checked") == true ? false : true;
      $('#'+value+'checked').prop("checked", nilai_check);

      if (nilai_check == true){
        $('#'+value+'box').css({flex: '1 1 auto', width: '1%', border:'2px solid #FF7203', backgroundColor: '#FFE1C9'});
      } else {
        $('#'+value+'box').css({flex: '1 1 auto', width: '1%', border:'2px solid #D3D3D3', backgroundColor: 'white'});
      }

      console.log("nilai_check ", nilai_check);

      let checkData = this.dataPayload.find((element) => element.courier_id === value)
      if (checkData == undefined){
        this.dataPayload.push({
              "courier_id": value,
              "status": nilai_check == true ? 1 : 0
          })
      } else {
          checkData.status = nilai_check == true ? 1 : 0;
      }
    },
    saveCourier: async function()
    {
      //kiro
      // console.log("dataPayloads : ", this.dataPayload);
      const response = await authFetch("/order/courier/update_courier", {
          headers:{
              'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(this.dataPayload),
      });
      let json = await response.json();
      console.log("json : ", json);
      if (json.succes == 'ok'){
        this.loadCourier();
        const e = this.$refs;
        this.showItemCustomCourier();
      } 
    },
    onResize() {
      this.width_screen = window.innerWidth
      // console.log("========+> ", window.innerWidth)
    },
    showItemCustomCourier: function() {
      const e = this.$refs;
      $(e.formDialogKurir).modal("show");
	  },
  },
  components: {
    vSelect,
    maskedInput,
    CurrencyInput,
    Warehouse,
  },
  mounted: function () {
    this.$nextTick((val) => {
      window.addEventListener('resize', this.onResize);
    })
  },
};
</script>